.header-gradient {
    background: linear-gradient(
      135deg, /* This controls the direction of the gradient */
      #67d391, /* Light green */
      #48a4d6, /* Light blue */
      #7f4fdb /* Light purple */
    );
    background-size: cover;
    color: white; /* Ensure text color contrasts well */

  }